import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(() => ({
	root: {},
	actionContainer: {
		justifyContent: 'flex-end',
	},
}))

const AccountDetails = (props) => {
	const { auth, className, ...rest } = props

	const classes = useStyles()

	const [values, setValues] = useState({
		firstName: auth.givenName,
		lastName: auth.familyName,
		email: auth.email,
		phone: auth.user_metadata?.phone,
		city: auth.user_metadata?.geoip?.city_name,
		country: auth.user_metadata?.geoip?.country_code3,
	})

	const handleChange = (event) => {
		setValues({
			...values,
			[event.target.name]: event.target.value,
		})
	}

	return (
		<Card className={classNames(classes.root, className)}>
			<form autoComplete="off" noValidate>
				<CardHeader subheader="NOTICE The information can't be edited yet" title="Profile" />
				<Divider />
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								helperText="Please specify the first name"
								label="First name"
								margin="dense"
								name="firstName"
								onChange={handleChange}
								required
								value={values.firstName}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Last name"
								margin="dense"
								name="lastName"
								onChange={handleChange}
								required
								value={values.lastName}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Email Address"
								margin="dense"
								name="email"
								onChange={handleChange}
								required
								value={values.email}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Phone Number"
								margin="dense"
								name="phone"
								onChange={handleChange}
								type="number"
								value={values.phone}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="City"
								margin="dense"
								name="city"
								onChange={handleChange}
								type="city"
								value={values.city}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Country"
								margin="dense"
								name="country"
								onChange={handleChange}
								required
								value={values.country}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</CardContent>
				<Divider />
				<CardActions className={classes.actionContainer}>
					<Button variant="contained">Save details</Button>
				</CardActions>
			</form>
		</Card>
	)
}

AccountDetails.propTypes = {
	className: PropTypes.string,
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default connect(mapStateToProps, actions)(AccountDetails)
