import React from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AccountProfile from './AccountProfile'
import AccountDetails from './AccountDetails'
import { RoleCard } from '@components'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	roleContainer: {
		paddingTop: theme.spacing(4),
	},
}))

const ProfileContent = ({ auth }) => {
	const classes = useStyles()
	const roles = auth.app_metadata?.roles
	const title = auth.user_metadata?.title

	return (
		<div className={classes.root}>
			<Grid container spacing={4}>
				<Grid item lg={4} md={6} xl={4} xs={12}>
					<Grid>
						<AccountProfile />
					</Grid>
					{roles && roles.length ? (
						<Grid item className={classes.roleContainer}>
							
						</Grid>
					) : (
						<></>
					)}
				</Grid>
				<Grid item lg={8} md={6} xl={8} xs={12}>
					<AccountDetails />
				</Grid>
			</Grid>
		</div>
	)
}

ProfileContent.propTypes = {

}

const mapStateToProps = ({ auth }) => {
	return {
		
		auth,
	}
}

export default connect(mapStateToProps, actions)(ProfileContent)
