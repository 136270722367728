import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles((theme) => ({
	root: {},
	details: {
		display: 'flex',
	},
	avatar: {
		marginBottom: theme.spacing(1),
		height: 100,
		width: 100,
		flexShrink: 0,
		flexGrow: 0,
	},
	progress: {
		marginTop: theme.spacing(2),
	},
	uploadButton: {
		marginRight: theme.spacing(2),
	},
	actionContainer: {
		justifyContent: 'flex-end',
	},
}))

const AccountProfile = (props) => {
	const { auth, className, ...rest } = props

	const classes = useStyles()

	return (
		<Card className={classNames(classes.root, className)}>
			<CardContent>
				<div className={classes.details}>
					<div>
						<Avatar className={classes.avatar} src={auth.picture} />
						<Typography gutterBottom variant="h4">
							{auth.name || auth.nickname}
						</Typography>
						<Typography className={classes.locationText} color="textSecondary" variant="body1">
							{auth.user_metadata?.geoip?.city_name}, {auth.user_metadata?.geoip?.country_code3}
						</Typography>
						<Typography className={classes.dateText} color="textSecondary" variant="body1">
							{moment().format('hh:mm A')} ({auth.user_metadata?.geoip?.time_zone})
						</Typography>
					</div>
				</div>
				<div className={classes.progress}>
					<Typography gutterBottom variant="body1">
						Profile Completeness: 70%
					</Typography>
					<LinearProgress value={70} variant="determinate" />
				</div>
			</CardContent>
			<Divider />
			<CardActions className={classes.actionContainer}>
				<Button variant="text">Remove picture</Button>
				<Button className={classes.uploadButton} variant="contained">
					Upload picture
				</Button>
			</CardActions>
		</Card>
	)
}

AccountProfile.propTypes = {
	className: PropTypes.string,
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default connect(mapStateToProps, actions)(AccountProfile)
